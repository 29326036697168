import { useEffect, useReducer, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Flex, Layout, Table, Typography } from 'antd';
import { Header, Content } from 'antd/es/layout/layout';

import { columns } from './columns';
import { expandedRowRender } from './components/ExpandedTable';
import { Loader } from '../../common/components/Loader';

import style from './styles/style.module.scss';

import { ClearingDataType } from '../../api/clearing-api/classes';
import { ActionValueTypes, IActionTypes, filterReducer, initialFilters } from './filterReducer';
import { FilterBar } from './components/FilterBar';
import { useModulesContext } from '../../context/ModulesContextProvider';
import { SelectStaticFilter } from '../../common/components/SelectStaticFilter';
import { LoaderSmall } from '../../common/components/LoaderSmall';

const View = () => {
  const { ClearingStore } = useModulesContext();

  const [loading, setLoading] = useState(true);
  const [lastTimeUpdate, setLastTimeUpdate] = useState(new Date()); // todo-refetch
  const [loadingRefetch, setLoadingRefetch] = useState(false); // todo-refetch
  const [refetchInterval, setRefetchInterval] = useState<number | null>(5000); // todo-refetch

  const [filters, dispatch] = useReducer(filterReducer, initialFilters);

  useEffect(() => {
    const props = {
      ...filters,
    };

    ClearingStore.getClearingData(props).then(() => {
      // todo-refetch
      setLoading(false);
    });
    let interval: NodeJS.Timeout; // todo-refetch

    if (refetchInterval) {
      // todo-refetch
      interval = setInterval(() => {
        setLoadingRefetch(true);
        ClearingStore.getClearingData(props).then(() => {
          setLastTimeUpdate(new Date());
          setLoadingRefetch(false);
        });
      }, refetchInterval);
    }

    return () => clearInterval(interval);
  }, [ClearingStore, filters, refetchInterval]);

  function handleSelectFilter(value: ActionValueTypes, actionKey: IActionTypes) {
    dispatch({
      type: actionKey,
      value: value,
    });
  }

  function handleRefetchIntervalSelect(value: string) {
    if (value === '0') {
      setRefetchInterval(null);
    } else {
      setRefetchInterval(Number(value));
    }
  }

  const intervalsData = [0, 2, 5, 10, 20, 30].map((el) => {
    //todo-refetch
    if (el === 0) {
      return { label: `Off`, value: `0` };
    }

    return { label: `${el} сек`, value: `${el * 1000}` };
  });

  return (
    <>
      <Layout className={style.layout}>
        <Header className={style.layout__header_clearing}>
        <Typography className={style.module_header}>Клиринг</Typography>          
          <Flex className={style.header_content_selectInfo}>
            <SelectStaticFilter
              value={refetchInterval ? `${refetchInterval / 1000} сек` : 'Off'}
              width="100px"
              options={intervalsData}
              onSelect={handleRefetchIntervalSelect}
            />
            <Flex style={{ width: 140, height: 30 }} align="center" justify="center">
              {loadingRefetch ? (
                <LoaderSmall />
              ) : (
                <Typography
                  style={{ color: 'gray', textWrap: 'nowrap' }}
                >{`Last update: ${lastTimeUpdate.toLocaleTimeString()}`}</Typography>
              )}
            </Flex>
          </Flex>
        </Header>
        <Content className={style.layout__content}>
          <Flex vertical gap={10}>
            <Flex vertical gap={5}>
              <FilterBar filters={filters} onFilter={handleSelectFilter} />
            </Flex>
            {loading ? (
              <Loader />
            ) : (
              <Table
                rowKey={(record: ClearingDataType) => `${record.instrumentFrom.id}+${record.instrumentTo.id}`}
                dataSource={ClearingStore.data}
                bordered
                scroll={{ x: 2000 }}
                columns={columns({ ...filters })}
                expandable={{ expandedRowRender }}
              />
            )}
          </Flex>
        </Content>
      </Layout>
    </>
  );
};

export const Clearing = observer(View);
