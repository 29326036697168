import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';

import { GrayWrap } from '../../../../common/components/GrayWrap';
import { InputWrapper } from '../../../../common/components/InputWrapper';
import { IActionTypes, FiltersType, ActionValueTypes } from '../../filterReducer';
import { useModulesContext } from '../../../../context/ModulesContextProvider';
import { SelectStaticFilter } from '../../../../common/components/SelectStaticFilter';
import { selectProviders, SelectProviders, SelectRateTypes, selectRateTypes } from '../../../../common/types';
import { SelectDebounceFilter, TSelectDebounceValue } from '../../../../common/components/SelectDebounceFilter';
import { FilteringDateType } from '../../../../common/components/DateFilters/DateFilter';
import { DateOptionsFilter } from '../../../../common/components/DateFilters/DateOptionsFilter';
import { DateFilterTest } from '../../../../common/components/DateFilters/DateFilterTest';

type Props = {
  filters: FiltersType;
  onFilter: (value: ActionValueTypes, key: IActionTypes) => void;
};

const View = ({ filters, onFilter }: Props) => {
  const {
    CoinsStore: { getSelectCoins },
    CoinNetworkStore: { getSelectNetwork },
  } = useModulesContext();

  function handleCoinFromSelect(value: TSelectDebounceValue[]) {
    const selectedValue = value;
    onFilter({ selectDebounceValue: selectedValue }, 'coinFromId');
  }

  function handleCoinToSelect(value: TSelectDebounceValue[]) {
    const selectedValue = value;
    onFilter({ selectDebounceValue: selectedValue }, 'coinToId');
  }

  function handleNetworkFromSelect(value: TSelectDebounceValue[]) {
    const selectedValue = value;
    onFilter({ selectDebounceValue: selectedValue }, 'networkFromId');
  }

  function handleNetworkToSelect(value: TSelectDebounceValue[]) {
    const selectedValue = value;
    onFilter({ selectDebounceValue: selectedValue }, 'networkToId');
  }

  function handleProviderSelect(value: SelectProviders) {
    const selectProvider = value === 'ALL' ? undefined : value;
    onFilter({ providerName: selectProvider }, 'providerName');
  }
  function handleRateTypeSelect(value: SelectRateTypes) {
    const selectRate = value === 'ALL' ? undefined : value;
    onFilter({ rateType: selectRate }, 'rateType');
  }

  function handleDateFilter(value: FilteringDateType) {
    onFilter({ filteringDate: value }, 'filteringDate');
  }

  const providerData = selectProviders.map((el) => ({ label: el, value: el }));
  const rateTypesData = selectRateTypes.map((el) => ({ label: el, value: el }));

  return (
    <Flex style={{ overflow: 'scroll' }} vertical gap={10}>
      <GrayWrap width="1150px">
        <Flex justify="start" gap={10}>
          <InputWrapper titleName="Монета инструмента 1">
            <SelectDebounceFilter
              value={filters.coinFromId ? [filters.coinFromId] : []}
              fetchOptions={getSelectCoins}
              onChange={handleCoinFromSelect}
              mode="multiple"
              maxCount={1}
              placeholder="Select from"
              style={{ width: '150px' }}
            />
          </InputWrapper>
          <InputWrapper titleName="Монета инструмента 2">
            <SelectDebounceFilter
              value={filters.coinToId ? [filters.coinToId] : []}
              fetchOptions={getSelectCoins}
              onChange={handleCoinToSelect}
              mode="multiple"
              maxCount={1}
              placeholder="Select to"
              style={{ width: '150px' }}
            />
          </InputWrapper>
          <InputWrapper titleName="Сеть инструмента 1">
            <SelectDebounceFilter
              value={filters.networkFromId ? [filters.networkFromId] : []}
              fetchOptions={getSelectNetwork}
              onChange={handleNetworkFromSelect}
              mode="multiple"
              maxCount={1}
              placeholder="Select from"
              style={{ width: '150px' }}
            />
          </InputWrapper>
          <InputWrapper titleName="Сеть инструмента 2">
            <SelectDebounceFilter
              value={filters.networkToId ? [filters.networkToId] : []}
              fetchOptions={getSelectNetwork}
              onChange={handleNetworkToSelect}
              mode="multiple"
              maxCount={1}
              placeholder="Select to"
              style={{ width: '150px' }}
            />
          </InputWrapper>
          <InputWrapper titleName="Провайдер">
            <SelectStaticFilter<SelectProviders>
              options={providerData}
              onSelect={handleProviderSelect}
              value={filters.providerName ? filters.providerName : 'ALL'}
            />
          </InputWrapper>
          <InputWrapper titleName="rateType">
            <SelectStaticFilter<SelectRateTypes>
              options={rateTypesData}
              onSelect={handleRateTypeSelect}
              value={filters.rateType ? filters.rateType : 'ALL'}
            />
          </InputWrapper>
        </Flex>
      </GrayWrap>
      <GrayWrap width="420px">
        <DateFilterTest
          filteringDate={{ dateFrom: filters.dateFrom, dateTo: filters.dateTo }}
          onDateFilter={handleDateFilter}
        />
      </GrayWrap>
    </Flex>
  );
};

export const FilterBar = observer(View);
