import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Header, Content } from 'antd/es/layout/layout';
import { Flex, Layout, Table, Tooltip, Typography } from 'antd';

import style from './style.module.scss';

import { ReactComponent as BackIcon } from '../../../../assets/icon/Back.svg';

import { GrayWrap } from '../../../../common/components/GrayWrap';
import { Loader } from '../../../../common/components/Loader';
import { columns } from './columns';
import { ExDetails } from './components/ExDetails';
import { ExTransactionDetails } from './components/ExTransactionDetails';
import { ExDetailsCommentForm } from './components/ExDetailsCommentForm';
import { ExInOutDetails } from './components/ExInOutDetails';
import { useModulesContext } from '../../../../context/ModulesContextProvider';
import { LoaderSmall } from '../../../../common/components/LoaderSmall';
import { SelectStaticFilter } from '../../../../common/components/SelectStaticFilter';
import { InfoCircleTwoTone } from '@ant-design/icons';

const View = () => {
  const { ExchangesStore } = useModulesContext();
  const { id: claimId } = useParams<string>();

  const [loadingRefetch, setLoadingRefetch] = useState(false);
  const [lastTimeUpdate, setLastTimeUpdate] = useState(new Date());
  const [refetchInterval, setRefetchInterval] = useState<number | null>(5000);

  useEffect(() => {
    if (typeof claimId === 'string') {
      ExchangesStore.getOneExchange(claimId);
    }

    let interval: NodeJS.Timeout;

    if (refetchInterval && typeof claimId === 'string') {
      interval = setInterval(() => {
        setLoadingRefetch(true);
        ExchangesStore.getOneExchange(claimId).then(() => {
          setLastTimeUpdate(new Date());
          setLoadingRefetch(false);
        });
      }, refetchInterval);

      return () => clearInterval(interval);
    }
  }, [ExchangesStore, claimId, refetchInterval]);

  const navigate = useNavigate();

  const intervalsData = [0, 2, 5, 10, 20, 30].map((el) => {
    if (el === 0) {
      return { label: `Off`, value: `0` };
    }

    return { label: `${el} сек`, value: `${el * 1000}` };
  });

  function handleRefetchIntervalSelect(value: string) {
    if (value === '0') {
      setRefetchInterval(null);
    } else {
      setRefetchInterval(Number(value));
    }
  }

  return ExchangesStore?.oneClaimData?.id !== undefined && typeof claimId === 'string' ? (
    <Layout className={style.layout}>
      <Header className={style.layout__header_exchange}>
        <Flex gap={10} style={{ alignSelf: 'flex-start' }}>
          <BackIcon style={{ cursor: 'pointer' }} onClick={() => navigate(`/exchanges`)} />
          <Tooltip title={claimId}>
            <Flex gap={10}>
              <Typography className={style.module_header}>{claimId.slice(0, 4)}</Typography>
              <InfoCircleTwoTone style={{ fontSize: 20 }} />
            </Flex>
          </Tooltip>
        </Flex>

        <Flex className={style.updateInfo}>
          <SelectStaticFilter
            value={refetchInterval ? `${refetchInterval / 1000} сек` : 'Off'}
            width="fit-content"
            options={intervalsData}
            onSelect={handleRefetchIntervalSelect}
          />

          {loadingRefetch ? (
            <LoaderSmall />
          ) : (
            <Typography
              style={{ color: 'gray', textWrap: 'nowrap' }}
            >{`Last update: ${lastTimeUpdate.toLocaleTimeString()}`}</Typography>
          )}
        </Flex>
      </Header>
      <Content className={style.layout__content_OneExchange}>
        <ExDetails />
        <ExInOutDetails />
        <ExTransactionDetails />
        <ExDetailsCommentForm id={claimId} />
        <GrayWrap>
          <div className={style.table_header}>
            <Typography className={style.info_block_title}>Order events</Typography>
          </div>
          <Table
            rowKey="id"
            dataSource={ExchangesStore.oneClaimData.claim_event_list}
            columns={columns()}
            scroll={{ x: 1000, y: 2000 }}
            pagination={false}
            bordered
          />
        </GrayWrap>
      </Content>
    </Layout>
  ) : (
    <Loader />
  );
};

export const Exchange = observer(View);
