import { API } from '..';
import { getToken } from '../../context/AuthContextProvider';
import { getClearingDataParams, GetClearingDataResponse } from './classes';

const PATHS = {
  CLEARING_STATS: '/api/clearing/stats',
};

export const ClearingAPI = {
  getClearingData: async (props: getClearingDataParams) => {
    const token = getToken();
    return await API.get<GetClearingDataResponse>({
      url: PATHS.CLEARING_STATS,
      params: { ...props },
      headers: {
        'x-access-token': token,
      },
    });
  },
};
