import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';

import { ClaimStatus } from '../../api/exchanges-api/classes';
import { TFileExtentions } from '../types';

export const getColor = (status: ClaimStatus) => {
  // colors
  const gray = 'gray';
  const yellow = '#EAD61B';
  const red = 'red';
  const green = 'green';
  switch (status) {
    case ClaimStatus.CONFIRM:
      return [yellow, gray, gray, gray];
    case ClaimStatus.EXCHANGE:
      return [green, green, yellow, gray];
    case ClaimStatus.SEND:
      return [green, green, green, yellow];
    case ClaimStatus.FINISH:
      return [green, green, green, green];
    case ClaimStatus.FAIL:
      return [green, red, red, red];
    case ClaimStatus.REFUND:
      return [green, red, red, red];
    case ClaimStatus.HOLD:
      return [green, red, gray, gray];
    case ClaimStatus.EXPIRE:
      return [red, gray, gray, gray];
    case ClaimStatus.WAIT_DEPOSIT:
    default:
      return [gray, gray, gray, gray];
  }
};

/// ----
// todo - необходимо вычислять кол-во прошедеших дней если ожидание длится больше суток
export function getTimeDiff(diference: number) {
  const hoursFromDate = new Date(diference).toISOString().substring(11, 13); // take string "HH"
  const timeDate = new Date(diference).toISOString().substring(13, 19); // take string ":MM:SS"
  let hours = Number(hoursFromDate); // count of hours

  const [year, month, day] = new Date(diference).toISOString().substring(0, 10).split('-'); // 1970 01 01 + difference

  let resultTimeDifference = '';

  if (day !== '01') {
    const plusHours = (Number(day) - 1) * 24;
    hours += plusHours;
  }

  if (year !== '1970') {
    resultTimeDifference = '>Year';
    console.log(`Разница от 1970-01-01Т00:00:00 -> ${new Date(diference).toISOString()}`);
    return resultTimeDifference;
  }

  if (month !== '01') {
    resultTimeDifference = '>Mounth';
    console.log(`Разница от 1970-01-01Т00:00:00 -> ${new Date(diference).toISOString()}`);
    return resultTimeDifference;
  }

  resultTimeDifference = `${hours}${timeDate}`;
  return resultTimeDifference;
}
// -----

export const getDate = (stringDate: string) => {
  let date = new Date(stringDate);
  return date;
};

export const copyFunction = (textToCopy: string): void => {
  if (textToCopy === '') {
    toast.error('Try to copy empty string');
  }
  // Adding text value to clipboard using copy function
  let isCopy = copy(textToCopy);
  //Dispalying notification

  if (isCopy) {
    toast.success('Copied to Clipboard');
  } else {
    toast.error('Something go wrong when coping');
  }
};

export const downloadFunction = (
  filenameWithoutExtention: string,
  extention: TFileExtentions,
  downloadStringValue: string
) => {
  fileDownload(downloadStringValue, filenameWithoutExtention + '.' + extention);
};

export function checkTypeF(arr: readonly string[], str: string) {
  // todo remove this finction and add to reducers prop with actual type
  let result: boolean = false;

  arr.forEach((el) => {
    if (el === str) {
      result = true;
    }
  });
  return result;
}

// export const dataGenerator = () => {
//   let data: ClearingDataType[] = [];
//   for (let i = 0; i < 3; i++) {
//     data.push({
//       id: `3c56c7a0-cc6e-4807-987a-b3cf0af247d8${i}`,
//       instrument_to_id: '910de4ad-e097-4978-8616-0b55e86e9d0f',
//       instrument_from_id: 'ccb73a6d-4d64-4583-8b9e-9d832034c8fd',
//       totalQuantity: 40,
//       totalAmountIn: 110,
//       totalAmountOut: 150,
//       totalComissionOur: 2450,
//       totalComissionProviders: 5150,
//       details: [
//         {
//           id: `200${i}`,
//           providerName: 'changelly',
//           quantity: 40,
//           amountIn: 1000,
//           amountOut: 2000,
//           commisionProvider: 2000,
//           comissiounOur: 1000,
//         },
//         {
//           id: `300${i}`,
//           providerName: 'LetsExchange',
//           quantity: 40,
//           amountIn: 10200 + i,
//           amountOut: 2000 + i * 3,
//           commisionProvider: 2000 + i * 5,
//           comissiounOur: 1000,
//         },
//       ],
//       instrument_from: {
//         id: 'ccb73a6d-4d64-4583-8b9e-9d832034c8fd',
//         key: 'usdt-TRX',
//         name: 'Tether Tron Chain',
//         image_url: 'https://cdn.changelly.com/icons-colored/usdtrx.png',
//         priority: 1,
//         is_in_active: true,
//         is_out_active: true,
//         coin_id: '36562bdb-3b89-4daf-94aa-9bce53c6a333',
//         network_id: 'e9954495-5157-465c-876a-cd8e88ee3a48',
//         coin: {
//           id: '36562bdb-3b89-4daf-94aa-9bce53c6a333',
//           ticker: 'usdt' + i,
//         },
//         network: {
//           id: 'e9954495-5157-465c-876a-cd8e88ee3a48',
//           ticker: 'TRX' + i,
//         },
//       },
//       instrument_to: {
//         id: '910de4ad-e097-4978-8616-0b55e86e9d0f',
//         key: 'usdt-TON',
//         name: 'Tether TON Chain',
//         image_url: 'https://cdn.changelly.com/icons-colored/usdton.png',
//         priority: 9999,
//         is_in_active: true,
//         is_out_active: true,
//         coin_id: '36562bdb-3b89-4daf-94aa-9bce53c6a333',
//         network_id: '68d971e3-6e18-4582-b76c-1f6eab16e594',
//         coin: {
//           id: '36562bdb-3b89-4daf-94aa-9bce53c6a333',
//           ticker: 'usdt' + i,
//         },
//         network: {
//           id: '68d971e3-6e18-4582-b76c-1f6eab16e594',
//           ticker: 'TON' + i,
//         },
//       },
//     });
//   }
//   return data;
// };
