import { TableColumnsType, Table } from 'antd';
import { ClearingDataType, ProviderStatsListItemDataType } from '../../../../api/clearing-api/classes';
import Typography from 'antd/es/typography/Typography';

export const expandedRowRender = (record: ClearingDataType) => {
  const columns: TableColumnsType<ProviderStatsListItemDataType> = [
    {
      title: 'Provider',
      render: ({ providerName }: ProviderStatsListItemDataType) => <Typography>{providerName}</Typography>,
    },
    {
      title: 'Count',
      render: ({ amountData }: ProviderStatsListItemDataType) => <Typography>{amountData.countTotal}</Typography>,
    },
    {
      title: 'countFix',
      render: ({ amountData }: ProviderStatsListItemDataType) => <Typography>{amountData.countFix}</Typography>,
    },
    {
      title: 'countMarket',
      render: ({ amountData }: ProviderStatsListItemDataType) => <Typography>{amountData.countMarket}</Typography>,
    },
    {
      title: 'Sum from',
      render: ({ amountData }: ProviderStatsListItemDataType) => <Typography>{amountData.amountFrom}</Typography>,
    },
    {
      title: 'Sum to',
      render: ({ amountData }: ProviderStatsListItemDataType) => <Typography>{amountData.amountTo}</Typography>,
    },
    {
      title: 'Sum Expected From',
      render: ({ amountData }: ProviderStatsListItemDataType) => (
        <Typography>{amountData.amountExpectedFrom}</Typography>
      ),
    },
    {
      title: 'Sum Expected To',
      render: ({ amountData }: ProviderStatsListItemDataType) => <Typography>{amountData.amountExpectedTo}</Typography>,
    },
    {
      title: 'Комиссия наша',
      render: ({ amountData }: ProviderStatsListItemDataType) => <Typography>{amountData.amountFeeOur}</Typography>,
    },

    {
      title: 'Комиссия провайдера',
      render: ({ amountData }: ProviderStatsListItemDataType) => (
        <Typography>{amountData.amountFeeExternal}</Typography>
      ),
    },
    {
      title: 'Комиссия сети',
      render: ({ amountData }: ProviderStatsListItemDataType) => <Typography>{amountData.amountFeeNetwork}</Typography>,
    },
  ];

  return (
    <Table bordered rowKey="providerName" columns={columns} dataSource={record.providerStatsList} pagination={false} />
  );
};